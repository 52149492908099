import React from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';

import { ThemeProvider } from 'common/context/theme';
import { SocialGroupsApp } from 'common/components/SocialGroupsApp';

import { COMPONENT } from 'settings/consts';

import type { IVMProps } from '../../../vm/types';

import { Widget } from './Widget';

COMPONENT.name = 'FeedWidget';

function FeedWidget(props: WidgetProps<IVMProps>) {
  return (
    <SocialGroupsApp {...props}>
      <ThemeProvider host={props.host}>
        <Widget />
      </ThemeProvider>
    </SocialGroupsApp>
  );
}

export default FeedWidget;
